<template>
  <div class="upm-dac">
    <!-- No results -->
    <template v-if="hasTokenError">
      <no-results
        title="Oops, something went wrong!"
        message="Our domain widget encountered a problem. If the issue
  persists, please get in touch."
      />
    </template>

    <!-- Loading -->
    <template v-else-if="!accessToken">
      <span class="stp-loading" />
    </template>

    <!-- Results -->
    <template v-else>
      <form @submit.prevent="search">
        <div class="field is-grouped">
          <div class="control is-expanded">
            <input
              v-model="domain"
              class="input"
              type="text"
              placeholder="Search..."
              :disabled="isLoadingMore"
              @focus="isTyping = true"
              @blur="isTyping = false"
            />
          </div>
          <button
            class="button is-dark"
            :class="{ 'is-loading': isLoading }"
            :disabled="!sld || isLoadingMore"
          >
            Search
          </button>
        </div>
      </form>

      <!-- Loading -->
      <span v-if="isLoading" class="stp-loading" />

      <!-- Error -->
      <no-results
        v-else-if="hasError"
        title="Oops, something went wrong!"
        message="An error occurred while fetching the domain availability."
        :cta="{ label: 'Try again' }"
        @click="search"
      />
      <!-- Results -->
      <template v-else-if="orderedDomains.length">
        <div class="upm-dac-results has-margin-top-150">
          <domain-row
            v-for="domain in orderedDomains"
            :key="`${domain.pid}-${domain.sld + domain.tld}`"
            :domain="domain"
            :class="{ 'is-exact-match': domain.tld === tld }"
            :on-add-to-basket="onAddToBasket"
          />
        </div>
        <button
          v-if="domains.length < totalResults"
          type="button"
          class="button is-outlined is-rounded is-fullwidth has-margin-y-100"
          :class="{ 'is-loading': isLoadingMore }"
          :disabled="!sld"
          @click="loadMoreResults()"
        >
          Load more
        </button>
        <slot />
      </template>
      <!-- No Results -->
      <no-results
        v-else-if="noResults"
        title="No available domains!"
        message="Your search returned 0 results. Please try again."
      />
    </template>
  </div>
</template>

<script>
import axios from "axios";
import _orderBy from "lodash/orderBy";
import _compact from "lodash/compact";

const API_URL = process.env.VUE_APP_API_SERVER;

export default {
  name: "UpmDac",
  data() {
    return {
      cancelSearch: null,
      domain: "",
      domains: [],
      guestToken: "",
      refreshToken: "",
      hasError: false,
      hasTokenError: false,
      isLoading: false,
      isLoadingMore: false,
      isTyping: false,
      noResults: false,
      querySearchParam: "upm-dac-search",
      refreshCount: 0,
      shouldPushToHistory: true,
      timeout: null,
      totalResults: 0,
      validDomainRegex: /[^a-zA-Z0-9\-.]/g,
    };
  },
  props: {
    authToken: {
      type: String,
      required: false,
      default: "",
    },
    basketId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    currencyCode: {
      type: String,
      required: false,
      default: null,
    },
    currencyId: {
      type: String,
      required: false,
      default: null,
    },
    coupons: {
      type: Array,
      required: false,
      default: () => [],
    },
    orderConfigUrl: {
      type: String,
      required: false,
      default: "",
    },
    onAddToBasket: {
      type: Function,
      required: false,
      default: null,
    },
    limit: {
      type: Number,
      required: false,
      default: 10,
    },
    with: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  components: {
    "no-results": () => import("./components/NoResults.vue"),
    "domain-row": () => import("./components/DomainRow.vue"),
  },
  watch: {
    sld(value) {
      if (!value) {
        this.updateQuery();
        if (this.cancelSearch) {
          this.cancelSearch();
          this.isLoading = false;
          this.isLoadingMore = false;
        }
        return;
      }
      if (value.length < 3) return;
      this.debounce(this.search);
    },
    tld(newVal, oldVal) {
      if ((oldVal && !newVal) || newVal.length > 2) this.debounce(this.search);
    },
  },
  created() {
    window.onpopstate = this.pushToHistory;
  },
  async mounted() {
    if (!this.authToken.length) {
      await this.createGuestToken();
    }
    this.domain = this.getQueryDomain();
    if (this.accessToken.length) {
      this.$watch("authToken", this.search);
    }
  },
  computed: {
    sanitizedDomain() {
      return `${this.domain}`.replace(/^(https?:\/\/)?(w{2,}\.)?/i, "");
    },
    accessToken() {
      return this.authToken || this.guestToken;
    },
    sld() {
      const sld = this.sanitizedDomain.replace(this.validDomainRegex, "");
      return (sld.split(".")[0] || "").toLowerCase();
    },
    tld() {
      let sld = this.sanitizedDomain
        .replace(this.validDomainRegex, "")
        .split(".");
      sld.shift();
      sld = sld.join(".");
      return sld ? `.${sld}` : "";
    },
    orderedDomains() {
      return _orderBy(
        this.domains,
        (domain) => domain.tld === this.tld,
        "desc"
      );
    },
  },
  methods: {
    pushToHistory() {
      const currentDomain = this.getQueryDomain();
      if (this.domain === currentDomain) return;
      this.shouldPushToHistory = false;
      this.domain = currentDomain;
    },
    getQueryDomain() {
      return (
        new URLSearchParams(window.location.search).get(
          this.querySearchParam
        ) || ""
      );
    },
    buildQueryParams() {
      const params = new URLSearchParams(window.location.search);
      let queryParams = {};
      for (let value of params.keys()) {
        queryParams[value] = params.get(value);
      }
      if (this.domain.length) {
        queryParams[this.querySearchParam] = this.domain;
      } else {
        delete queryParams[this.querySearchParam];
      }

      return queryParams;
    },
    updateQuery() {
      const currentDomain = this.getQueryDomain();
      if (!this.shouldPushToHistory || currentDomain === this.domain) {
        return;
      }
      window.history.pushState(
        null,
        null,
        `?${new URLSearchParams(this.buildQueryParams()).toString()}`
      );
      this.shouldPushToHistory = true;
    },
    onError(error) {
      this.domains = [];
      this.totalResults = 0;
      if (this.isCancelled(error)) return;
      if (!this.is401(error)) {
        this.isLoading = false;
        this.isLoadingMore = false;
        this.hasError = true;
        return;
      }
      if (this.authToken.length) {
        this.$emit("unauthorised");
      } else if (!this.refreshCount) {
        this.refreshCount++;
        this.doTokenRefresh();
      } else {
        this.refreshCount = 0;
      }
    },
    is401(error) {
      return error?.response?.status === 401;
    },
    isCancelled(error) {
      return axios.isCancel(error);
    },
    buildDomainsData(responseData, fromScratch = true) {
      if (fromScratch) this.domains = [];
      responseData.forEach((domain) => {
        const terms = _orderBy(domain.prices, "billing_cycle_months", "asc");
        const term = terms[0];
        if (!term) return;
        this.domains.push({
          _product: domain,
          sld: this.sld,
          tld: domain.tld,
          price_formatted: term.price_formatted,
          price_discounted_formatted: term.price_discounted_formatted,
          pid: domain.id,
          sub_pids: domain.sub_product_id,
          order_url: `${this.orderConfigUrl}?pid=${domain.id}&bcm=${
            term.billing_cycle_months
          }&pfields[sld]=${this.sld}${
            domain.sub_product_id ? `&sub_pids=${domain.sub_product_id}` : ""
          }`,
          domain_available: domain.domain_available,
          billing_cycle_months: term.billing_cycle_months,
          billing_cycle_years: term.billing_cycle_months / 12,
        });
      });
    },
    onSuccess(response, offset = undefined) {
      this.refreshCount = 0;
      this.buildDomainsData(
        this.domain ? response.data.data : [],
        !Number.isInteger(offset)
      );
      this.totalResults = response?.data?.total || 0;
      this.noResults = this.domain ? !this.domains.length : false;
      this.isLoading = false;
      this.isLoadingMore = false;
      this.hasError = false;
    },
    search() {
      if (this.timeout) clearTimeout(this.timeout);
      if (!this.sld) {
        this.domain = "";
        this.domains = [];
        this.totalResults = 0;
        this.hasError = false;
        this.isLoading = false;
        this.updateQuery();
        return;
      }
      this.isLoading = true;
      this.noResults = false;
      this.updateQuery();
      return this.callApi();
    },
    loadMoreResults() {
      if (this.domains.length >= this.totalResults) return;
      this.isLoadingMore = true;
      return this.callApi(this.domains.length);
    },
    callApi(offset = undefined) {
      return axios
        .get(
          `${API_URL}api/modules/web_hosting/domains/search?${_compact([
            `with=${["prices", "options", "options.prices", "attributes", ...this.with].join()}`,
            `sld=${this.sld}`,
            this.tld && `tld=${this.tld}`,
            this.currencyCode && `currency_code=${this.currencyCode}`,
            this.currencyId && `currency_id=${this.currencyId}`,
            // Pass pagination limit
            `limit=${this.limit}`,
            // Pass pagination offset
            Number.isInteger(offset) && `offset=${offset}`,
            // Pass coupon(s) to show prices with discounts
            Array.isArray(this.coupons) &&
              this.coupons.length &&
              `promotions=${this.coupons.join()}`,
            // Pass basketId to give context for available discounts
            Number.isInteger(this.basketId) && `basket_id=${this.basketId}`,
          ]).join("&")}`,
          {
            cancelToken: new axios.CancelToken((c) => {
              this.cancelSearch = c;
            }),
            headers: {
              Authorization: `Bearer ${this.accessToken}`,
            },
          }
        )
        .then((response) => {
          this.onSuccess(response, offset);
          this.cancelSearch = null;
        })
        .catch(this.onError);
    },
    debounce(search) {
      if (this.timeout) clearTimeout(this.timeout);
      if (this.cancelSearch) this.cancelSearch();
      this.timeout = setTimeout(
        () => {
          search();
        },
        this.isTyping ? 500 : 0
      );
    },
    createGuestToken() {
      return axios
        .post(`${API_URL}oauth/access_token`, {
          grant_type: "guest",
        })
        .then((response) => {
          this.guestToken = response.data.access_token;
          this.refreshToken = response.data.refresh_token;
        })
        .catch(() => (this.hasTokenError = true));
    },
    doTokenRefresh() {
      return axios
        .post(`${API_URL}oauth/access_token`, {
          refresh_token: this.refreshToken,
          grant_type: "refresh_token",
        })
        .then((response) => {
          this.guestToken = response.data.access_token;
          this.refreshToken = response.data.refresh_token;
          this.hasTokenError = false;
          this.search();
        })
        .catch((error) => {
          if (this.is401(error))
            return this.createGuestToken().then(this.search);
          this.hasTokenError = true;
        });
    },
  },
};
</script>

<style lang="scss">
@import "./assets/sass/bulma/custom-build";
</style>

<style lang="scss" scoped>
@import "./assets/sass/bulma/custom-variables.scss";

.upm-dac {
  color: var(--upm-dac-color, $dark);
}

span.stp-loading {
  display: flex;
  font-size: 3rem;
  margin: 1em;
  &:after {
    content: "";
    display: block;
    width: 1em;
    height: 1em;
    margin: auto;
    border-radius: 50%;
    border: 0.125em solid var(--upm-dac-color, $dark);
    border-top-color: transparent;
    animation: loading 0.666s linear infinite;
    box-sizing: border-box;
    opacity: 0.2;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
